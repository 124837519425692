import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CheckboxMultiSelectT } from "../../../../content/static/onboardingQuestions";
import { AppText, FlexDiv } from "../../../ui";
import { useOnboardingContext } from "../OnboardingContext";
import theme from "../../../../util/theme";
import { mobileSize } from "../../../../util/variables";
import { web_acq_question_click } from "../../../../services/mixpanel/acquisition-events";

type CheckboxMultiselectQuestionProps = CheckboxMultiSelectT & {
  answer_key: string;
};

interface CheckboxMultiselectProps {
  label: string;
  selected: boolean;
  updateChoice: (value: string, exists: boolean) => void;
}

const CheckboxMultiselectQuestion: React.FC<CheckboxMultiselectQuestionProps> = ({
  id,
  title,
  subtitle,
  options,
  answer_key,
  max_choices,
}) => {
  const [choices, setChoices] = useState<string[]>([]);
  const { updateOnboardingAnswer } = useOnboardingContext();

  useEffect(() => {
    updateOnboardingAnswer(answer_key, choices.join(", "));
  }, [choices, answer_key]);

  const updateChoice = React.useCallback(
    (value: string, exists: boolean) => {
      web_acq_question_click(id);
      if (exists) {
        // remove choice from array
        const idx = choices.indexOf(value);
        const newChoices = choices.slice();
        newChoices.splice(idx, 1);
        setChoices(newChoices);
        return;
      }

      if (max_choices && choices.length >= max_choices) {
        return;
      }

      // add choice to array
      setChoices(prev => [...prev, value]);
    },
    [choices, id],
  );

  return (
    <FlexDiv
      direction="column"
      style={{ position: "relative", overflow: "hidden", height: "100%" }}
    >
      <TextWrapper>
        <AppText
          noMargin
          fontSize={24}
          mobileFontSize={20}
          fontWeight={500}
          style={{ maxWidth: 468 }}
        >
          {title}
        </AppText>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TextWrapper>
      <OptionsContainer>
        {options.length > 0 &&
          options.map(option => (
            <CheckboxMultiSelect
              key={option.label}
              label={option.label}
              updateChoice={updateChoice}
              selected={choices.includes(option.label)}
            />
          ))}
      </OptionsContainer>
      <Shadow />
    </FlexDiv>
  );
};

const TextWrapper = styled.div`
  padding: 0 10px;
  text-align: center;
  @media ${mobileSize} {
    padding: 0 32px;
  }
`;

const Subtitle = styled(AppText as any).attrs({
  fontSize: 16,
  mobileFontSize: 14,
})`
  margin: 0;
  margin-top: 12px;
  @media ${mobileSize} {
    margin-top: 8px;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  gap: 24px;
  margin-top: 60px;
  padding: 12px;
  height: 100%;
  max-width: 100vw;
  overflow: auto;

  @media ${mobileSize} {
    margin-top: 24px;
    padding: 8px 32px;
    max-height: calc(100% - 60px); // height of text + margins
    gap: 10px;
  }
`;

const Shadow = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 100vw;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  @media ${mobileSize} {
    display: block;
  }
`;

const CheckboxMultiSelect: React.FC<CheckboxMultiselectProps> = ({
  label,
  updateChoice,
  selected,
}) => {
  return (
    <Container selected={selected} onClick={() => updateChoice(label, selected)}>
      <MultiCheckBoxInput>
        <input type="checkbox" value={label} checked={selected} />
        <CustomCheckbox />
      </MultiCheckBoxInput>
      <AppText noMargin mobileFontSize={16} textAlign="left" style={{ maxWidth: 264 }}>
        {label}
      </AppText>
    </Container>
  );
};

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 24px;

  width: 468px;
  max-width: 100%;
  height: 86px;
  background-color: ${props => (props.selected ? theme.SELECTED_COLOR : theme.WHITE_COLOR)};
  outline: ${props => (props.selected ? `1px solid ${theme.SELECTED_BORDER_COLOR}` : "none")};
  border-radius: 8px;
  box-shadow: ${props =>
    props.selected ? "0px 1px 7px rgba(44, 39, 56, 0.14)" : "0px 2px 11px rgba(44, 39, 56, 0.14)"};
  cursor: pointer;
  @media ${mobileSize} {
    height: 64px;
    min-height: 64px;
  }
`;

export const MultiCheckBoxInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ span {
    border-color: transparent;
    &::before {
      opacity: 1;
      height: 16px;
      width: 8px;
      border-bottom: 2px solid ${theme.BLACK_COLOR};
      border-right: 2px solid ${theme.BLACK_COLOR};
    }
  }
`;

export const CustomCheckbox = styled.span`
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: 24px;
  border: 2px solid #d2d5d8;
  border-radius: 3px;
  background-color: ${theme.WHITE_COLOR};

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 6px;
    border-radius: 2px;
    opacity: 0;
    rotate: 45deg;
    transition-property: height, width, top, left;
    transition-duration: 0.15s; 
  }

  @media ${mobileSize} {
    margin-left: 16px;
  }
`;

export default CheckboxMultiselectQuestion;
