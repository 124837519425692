import React, { useState } from "react";
import styled from "styled-components";
import { YesNoT } from "../../../../content/static/onboardingQuestions";
import { AppText, FlexDiv } from "../../../ui";
import { useOnboardingContext } from "../OnboardingContext";
import theme from "../../../../util/theme";
import { mobileSize } from "../../../../util/variables";
import { web_acq_question_click } from "../../../../services/mixpanel/acquisition-events";
import { BackgroundCard1, Quote, MainCard, BackgroundCard2, ContentWrapper } from "./ScaleQuestion";

interface YesNoQuestionProps {
  data: YesNoT;
  nextQuestion: () => void;
}

interface SelectProps {
  label: "Yes" | "No";
  icon: string;
  selected: boolean;
  onClick: () => void;
  image?: string;
}

const YesNoQuestion: React.FC<YesNoQuestionProps> = ({
  data: { id, statement },
  nextQuestion,
}) => {
  const [choice, setChoice] = useState("");
  const { updateOnboardingAnswer } = useOnboardingContext();

  const onClick = (value: string) => {
    setChoice(value);
    updateOnboardingAnswer(id, choice);
    web_acq_question_click(id);
    setTimeout(() => {
      nextQuestion();
    }, 100);
  };

  return (
    <FlexDiv
      direction="column"
      align="center"
      mobileJustify="space-between"
      style={{ textAlign: "center", flexGrow: 1 }}
    >
      <AppText noMargin fontSize={24} mobileFontSize={20} fontWeight={500}>
        Do you agree?
      </AppText>
      <ContentWrapper>
        {/* Background cards */}
        <BackgroundCard1 />
        <BackgroundCard2 />
        {/* Main card */}
        <MainCard>
          <Quote />
          <AppText fontSize={24} mobileFontSize={20}>{statement}&rdquo;</AppText>
        </MainCard>
      </ContentWrapper>
      <OptionsContainer>
        <Select
          label="No"
          icon="👎"
          selected={choice === "No"}
          onClick={() => onClick("No")}
        />
        <Select
          label="Yes"
          icon="👍"
          selected={choice === "Yes"}
          onClick={() => onClick("Yes")}
        />
      </OptionsContainer>
    </FlexDiv>
  );
};

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 64px;
  /* margin-top: 80px; */
  margin-top: auto;
  padding: 12px;
  max-width: 100vw;
  overflow: auto;
  @media ${mobileSize} {
    /* position: fixed;
    bottom: 24px; */
    padding: 8px 32px 8px 32px;
    gap: 10px;
  }
`;

// ==========================================================================================

const Select: React.FC<SelectProps> = ({ label, icon, onClick, selected }) => {
  return (
    <Container selected={selected} label={label} onClick={onClick}>
      <AppText
        noMargin
        fontWeight={selected ? 500 : 400}
        mobileFontSize={16}
        style={{ maxWidth: 200 }}
      >
        {label}
      </AppText>
      <AppText noMargin>{icon}</AppText>
    </Container>
  );
};

const Container = styled.div<{ selected: boolean; label: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  width: 220px;
  max-width: 100%;
  height: 86px;

  background-color: ${({ selected, label }) =>
    selected ? (label === "Yes" ? theme.SELECTED_COLOR : "#FBE2E2") : theme.WHITE_COLOR};
  outline: ${({ selected, label }) =>
    selected ? `1px solid ${label === "Yes" ? theme.SELECTED_BORDER_COLOR : theme.RED}` : "none"};
  border-radius: 8px;
  box-shadow: ${props =>
    props.selected ? "0px 1px 7px rgba(44, 39, 56, 0.14)" : "0px 2px 11px rgba(44, 39, 56, 0.14)"};
  cursor: pointer;
  @media ${mobileSize} {
    height: 64px;
    min-height: 64px;
  }
`;

export default YesNoQuestion;
