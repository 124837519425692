import React, { useState } from "react";
import styled from "styled-components";
import { ScaleT } from "../../../../content/static/onboardingQuestions";
import { AppText, FlexDiv } from "../../../ui";
import { useOnboardingContext } from "../OnboardingContext";
import theme from "../../../../util/theme";
import { mobileSize } from "../../../../util/variables";
import { web_acq_question_click } from "../../../../services/mixpanel/acquisition-events";
// import ThumbUp from "../../../../content/img/icon/thumb-up.svg";
// import ThumbDown from "../../../../content/img/icon/thumb-down.svg";
import QuoteSrc from "../../../../content/img/icon/quotation-left-green.svg";

interface ScaleQuestionProps {
  data: ScaleT;
  nextQuestion: () => void;
}

const ScaleQuestion: React.FC<ScaleQuestionProps> = ({
  data: { id, statement },
  nextQuestion,
}) => {
  const [choice, setChoice] = useState("");
  const { updateOnboardingAnswer } = useOnboardingContext();

  const onClick = (value: string) => {
    setChoice(value);
    updateOnboardingAnswer(id, choice);
    web_acq_question_click(id);
    setTimeout(() => {
      nextQuestion();
    }, 100);
  };

  return (
    <FlexDiv
      direction="column"
      align="center"
      mobileJustify="space-between"
      style={{ textAlign: "center", flexGrow: 1 }}
    >
      <AppText noMargin fontSize={24} mobileFontSize={20} fontWeight={500}>
        Do you agree?
      </AppText>
      <ContentWrapper>
        {/* Background cards */}
        <BackgroundCard1 />
        <BackgroundCard2 />
        {/* Main card */}
        <MainCard>
          <Quote />
          <AppText fontSize={24} mobileFontSize={20}>{statement}&rdquo;</AppText>
        </MainCard>
      </ContentWrapper>
      <OptionsContainer>
        <ScaleButton
          fontSize={42}
          selectedColor={choice === "very negative" ? "#ff8e7ebf" : undefined}
          onClick={() => onClick("very negative")}
        >
          👎
        </ScaleButton>
        <ScaleButton
          fontSize={30}
          selectedColor={choice === "negative" ? "#ff8e7e66" : undefined}
          onClick={() => onClick("negative")}
        >
          👎
        </ScaleButton>
        <ScaleButton
          fontSize={42}
          selectedColor={choice === "neutral" ? "##ffe69966" : undefined}
          onClick={() => onClick("neutral")}
        >
          🤷
        </ScaleButton>
        <ScaleButton
          fontSize={30}
          selectedColor={choice === "positive" ? "#caffcb66" : undefined}
          onClick={() => onClick("positive")}
        >
          👍
        </ScaleButton>
        <ScaleButton
          fontSize={42}
          selectedColor={choice === "very positive" ? "#caffcbbf" : undefined}
          onClick={() => onClick("very positive")}
        >
          👍
        </ScaleButton>
      </OptionsContainer>
    </FlexDiv>
  );
};

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 16px;

  min-height: 310px;
  height: 55dvh;
  max-height: 370px;
  margin-top: 80px;

  @media ${mobileSize} {
    padding: 0 20px;
    margin-top: 40px;
    max-height: none;
  }
`;

const BackgroundCard = styled.div`
  position: absolute;
  /* bottom: -24px; */
  max-height: 40vh;
  height: 400px;
  /* width: calc(327px - 16px); */
  border-radius: 24px;
  background-color: ${theme.WHITE_COLOR};
  // TODO: update shadow
  box-shadow: 0px 1px 7px rgba(30, 30, 30, 0.14);
`;

export const BackgroundCard1 = styled(BackgroundCard)`
  /* bottom: -24px; */
  top: 24px;
  width: calc(327px - 16px);
`;

export const BackgroundCard2 = styled(BackgroundCard)`
  /* bottom: -12px; */
  top: 12px;
  width: calc(327px - 8px);
`;

export const MainCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  max-height: 40vh;
  height: 400px;
  width: 327px;
  text-align: left;
  padding: 24px;
  border-radius: 24px;
  background-color: ${theme.WHITE_COLOR};
  // TODO: update shadow
  box-shadow: 0px 1px 7px rgba(30, 30, 30, 0.14);
`;

export const Quote = styled.img.attrs({ src: QuoteSrc, alt: "quote" })`
  position: absolute;
  top: 20px;
  left: 16px;
  width: 46px;
  height: 42px;

  @media ${mobileSize} {
    width: 42px;
    height: 38px;
  }
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(60px, 1fr));
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 2px 8px 0px #A4A4A440;
  border-radius: 8px;
  margin-top: auto;
  
  @media ${mobileSize} {
    width: 90%;
  }
`;

const ScaleButton = styled.button<{ fontSize: number; selectedColor?: string }>`
  height: 96px;
  border-right: 1px solid #A4A4A440;
  background-color: ${({ selectedColor }) => selectedColor ?? theme.WHITE_COLOR};
  font-size: ${({ fontSize }) => fontSize}px;
  text-align: center;
  padding: 0;

  &:last-child {
    border-right: none;
  }

  @media ${mobileSize} {
    font-size: ${({ fontSize }) => fontSize * 0.8}px;
  }
`;

export default ScaleQuestion;
